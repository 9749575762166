Body {
  --theme-color: orange;
  background-color: black;
  font-family: Verdana, sans-serif;
}

.app {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}