.navbar-section {
    display: none;
}

.scrolled {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    display: flex;
    justify-content: space-around;
    width: 100%;
    z-index: 100;
    animation: appear 0.5s ease;
}

@keyframes appear {
    0% {
      opacity: 0;
    } 100% {
      opacity: 1;
    }
  }

.ken-johnson {
    color: var(--theme-color);
    font-size: 30px;
    font-weight: 400;
}

.ken-johnson:hover {
    color: var(--theme-color);
    transition: 1s;
}

.nav-links-container {
    display: flex;
    justify-content: space-around;
    width: 70%;
}

.nav-links-container-active {
    display: flex;
    justify-content: space-around;
    width: 70%;
}

.nav-links-navbar {
    line-height: 45px;
    width: 100px;
    text-align: center;
    border-radius: 8px;
    border: solid;
    border-width: 2px;
    color: white !important;
    border-color: white;
    background-color: black;
    cursor: pointer;
}

.nav-links-navbar:hover {
    background-color: var(--theme-color);
    color: black !important;
    font-weight: 500;
    border-color: black;
    transition: 1s;
}

.nav-links-container > .active {
    border-color: var(--theme-color);
    border-width: 2px;
}

.nav-links-container-active > .active {
    border-color: var(--theme-color);
    border-width: 2px;
}

.icons-navbar {
    height: 50px;
}

.linkedin-github {
    display: flex;
    justify-content: space-between;
    width: 20%;
}

.burger {
    display: none;
    cursor: pointer;
}

.burger div{
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px;
}

@media screen and (max-width: 768px) {
    .burger {
        display: block;
    }

    .nav-links-container {
        position: absolute;
        right: 0px;
        height: 91vh;
        top: 9vh;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        transform: translateX(0%);
        transition: transform 0.5s ease-in;
    }

    .nav-links-container-active {
        position: absolute;
        right: 0px;
        height: 91vh;
        top: 9vh;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;
    }

    .linkedin-github {
        width: 50%;
    }
}