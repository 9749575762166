.about-section {
  height: 100vh;
  position: relative;
}

.parallax {
  position: absolute;
  width: 100%;
  height: 15%;
}

.parallax-content {
  margin: auto;
  text-align: center;
  font-size: 60px;
  line-height: 15vh;
  color: white;
}

.about-section .left {
  height: 85%;
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 70px;
  font-size: 25px;
  color: white;
}

.about-section .right {
  height: 85%;
  width: 50%;
  float: right;
}

.about-section .right .top {
  
}

@media screen and (max-width: 768px) {

}