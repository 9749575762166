.footer-section {
  display: flex;
  justify-content: space-around;
  text-align: center;
  color: white;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .footer-section {
    flex-direction: column;
  }

  .e-mail {
    margin-bottom: 30px;
  }
}